import { teamLevel } from 'staticData/common';

// Define a constant to represent the specific A/B test identifier for portfolio tests
export const portfolioTest = 'iteration_202409_01';

/**
 * Function to determine if a user is part of a specific A/B test
 * @param {Object} userInfos - An object containing user information. Assumes property 'created_at' for user's creation timestamp.
 * @param {string} abTest - A string representing the A/B test identifier.
 * @returns {boolean} - Returns true if the user is in the specified A/B test; otherwise, returns false.
 */
export const isInTestAB = (userInfos = {}, abTest) => {
  const queryParams = new URLSearchParams(window.location.search);
  const query_testAB = queryParams.get('testAB');
  // If the user information object is empty or the A/B test identifier is not provided, the user cannot be in the test
  if (Object.keys(userInfos).length === 0 || !abTest) return false;

  // Check if the specified A/B test is the portfolio test
  if (abTest === portfolioTest) {
    if (
      userInfos?.permissions?.[portfolioTest] ||
      (userInfos.level >= teamLevel && query_testAB === 'portfolio')
    )
      return true;
  }

  // If none of the conditions are met, the user is not in the test
  return false;
};
