// in this page we put the general timer for the releasaes
export const now = new Date();

export const changePriceAndLimitsDateOctober2024 = new Date(
  '2024-10-03T14:30:00.000Z'
);
export const afterChangePriceOctober2024 =
  now > changePriceAndLimitsDateOctober2024;

// EXAMPLE
// const timer_START_SOLForEveryone = new Date('2024-05-03T12:00:00');
// const timer_END_SOLForEveryone = new Date('2024-05-11T00:00:00');
// export const banner_SOLForEveryone_unlocked =
//   now >= timer_START_SOLForEveryone && now < timer_END_SOLForEveryone;
// export const SOLForEveryone_unlocked = now >= timer_START_SOLForEveryone;

// dashboard banner
// export const timer_START_dashboard = new Date('2024-07-19T12:00:00');
// export const banner_START_dashboard = now > timer_START_dashboard;
// export const banner_endDate = new Date('2024-08-11T23:59:59');
// export const banner_dashboard =
//   now > timer_START_dashboard && now < banner_endDate;
