// import { goldColor } from 'staticData/common';
import { routes as textLang } from 'staticData/languages';
import { trackEvent } from 'services/externals/mixpanel';

export const frontendRoutes = [
  // EXAMPLE
  // {
  //   name: 'Dashboard', -> name displayed
  //   id: 'dashboard', -> use to set a id for marketing
  //   icon: 'chart-pie', -> icon displayed
  //   to: '/dashboard', -> path
  //   type: 'App', -> group of url (use in Vertical Nav)
  //   badge: { dynamic: true }, -> if a badge is dynamic you can set text and type directly in navbarVerticalMenuItem.js file (dynamicBadges)
  //   badge: {
  //     text: textLang.new, -> text of static badge
  //     type: 'info' -> color of background
  //   },
  //   navbarVertical: true, -> if true show the VERTICAL navbar in this path
  //   navbarTop: true, -> if true show the TOP navbar in this path
  //   navbarBottom: true, -> if true show the BOTTOM navbar in this path
  //   withCredentials: true, -> if true the user must be logged to see this url
  //   inBottomNavbar: true, -> if true is displayed in the bottom navbar
  //   inBottomCenter: true, -> if true is in the CENTER of the navbar bottom
  //   inBottomLeft: true, -> if true is in the LEFT of the navbar bottom
  //   inBottomRight: true, -> if true is in the RIGHT of the navbar bottom
  //   disableInMobile: true, -> if true is disable in navbar vertical in mobile mode
  //   mobileText: textLang.createBotMobile, -> if exist set a new text only for navbar Bottom
  //   active: true -> if is true is enabled in vertical navbar
  //   minLevel: min level to show route
  // },
  {
    name: 'Dashboard',
    id: 'dashboard',
    icon: 'chart-pie',
    to: '/',
    type: 'App',
    navbarVertical: true,
    navbarTop: true,
    navbarBottom: true,
    inBottomNavbar: true,
    bottomPosition: 0,
    bottomID: 'dashboard-footer',
    bottomTEAMPosition: 0,
    inBottomCenter: true,
    withCredentials: true,
    disableInMobile: true,
    active: true
  },
  {
    name: textLang.invest,
    icon: 'rocket',
    to: '/invest',
    type: 'App',
    badge: {
      text: { 'en-EN': 'New', 'it-IT': 'Nuovo' },
      type: 'info'
    },
    navbarVertical: true,
    navbarTop: true,
    navbarBottom: true,
    inBottomNavbar: true,
    bottomPosition: 2,
    bottomID: 'createbot-footer',
    bottomTEAMPosition: 2,
    inBottomLeft: true,
    mobileText: textLang.createBotMobile,
    withCredentials: true,
    disableInMobile: true,
    active: true
  },
  {
    name: 'Wallet',
    icon: 'wallet',
    to: '/wallet',
    type: 'App',
    navbarVertical: true,
    navbarTop: true,
    navbarBottom: true,
    bottomPosition: 1,
    bottomID: 'wallet-footer',
    bottomTEAMPosition: 1,
    inBottomNavbar: true,
    inBottomLeft: true,
    withCredentials: true,
    disableInMobile: true,
    active: true
  },
  // {
  //   name: textLang.insights,
  //   icon: 'poll',
  //   to: '/insights',
  //   type: 'App',
  //   badge: {
  //     text: textLang.new,
  //     type: 'info'
  //   },
  //   navbarVertical: true,
  //   navbarTop: true,
  //   navbarBottom: true,
  //   inBottomNavbar: true,
  //   inBottomRight: true,
  //   disableInMobile: true,
  //   withCredentials: true,
  //   minLevel: teamLevel,
  //   active: true
  // },
  {
    name: 'Sentiment',
    icon: 'newspaper',
    to: '/sentiment',
    type: 'App',
    navbarVertical: true,
    navbarTop: true,
    navbarBottom: true,
    inBottomNavbar: true,
    bottomPosition: 3,
    bottomID: 'sentiment-footer',
    bottomTEAMPosition: 3,
    inBottomRight: true,
    // showWithPermission: 'sentiment',
    disableInMobile: true,
    withCredentials: true,
    // minLevel: teamLevel,
    active: true
  },
  {
    name: textLang.virtualMode,
    icon: 'gamepad',
    to: '/virtual-mode',
    type: 'App',
    navbarVertical: true,
    navbarTop: true,
    navbarBottom: true,
    inBottomNavbar: true,
    inBottomRight: true,
    withCredentials: true,
    active: true
  },
  // {
  //   name: 'Top Scorers',
  //   icon: 'fire',
  //   to: '/top-scorers',
  //   type: 'App',
  //   navbarVertical: true,
  //   navbarTop: true,
  //   navbarBottom: true,
  //   inBottomNavbar: true,
  //   inBottomRight: true,
  //   withCredentials: true,
  //   active: true
  // },
  // {
  //   name: textLang.usageGuide,
  //   icon: 'file-alt',
  //   to: '/usage-guide',
  //   type: 'App',
  //   navbarVertical: true,
  //   navbarTop: true,
  //   navbarBottom: true,
  //   withCredentials: true,
  //   active: true
  // },
  {
    name: textLang.account,
    icon: 'user-circle',
    to: '/account',
    type: 'Account',
    navbarVertical: true,
    navbarTop: true,
    navbarBottom: true,
    inBottomNavbar: true,
    bottomPosition: 4,
    bottomID: 'account-footer',
    bottomTEAMPosition: 4,
    inBottomRight: true,
    withCredentials: true,
    disableInMobile: true,
    active: true
  },
  {
    name: 'Referral',
    icon: 'gift',
    to: '/rewards',
    type: 'Account',
    navbarVertical: true,
    navbarTop: true,
    navbarBottom: true,
    withCredentials: true,
    active: true
  },
  {
    name: 'riskForm',
    icon: 'gift',
    to: '/risk-form',
    type: '',
    navbarVertical: true,
    navbarTop: true,
    navbarBottom: true,
    withCredentials: true,
    active: true
  },
  {
    name: 'FAQ',
    icon: 'question-circle',
    to: 'https://hodlie.ai/faq/',
    testABportfolioLink: 'https://hodlie.ai/faq-sp/',
    target: '_blank',
    type: 'Account',
    navbarVertical: true,
    navbarTop: true,
    navbarBottom: true,
    withCredentials: true,
    active: true
  },
  // {
  //   name: textLang.announcements,
  //   icon: 'bullhorn',
  //   to: '/announcements',
  //   type: 'Account',
  //   navbarVertical: true,
  //   navbarTop: true,
  //   navbarBottom: true,
  //   withCredentials: true,
  //   active: true
  // },
  {
    name: 'Feedback',
    icon: ['far', 'comment'],
    to: 'https://hodlie.featurebase.app/',
    target: '_blank',
    type: 'Account',
    navbarVertical: true,
    navbarTop: true,
    navbarBottom: true,
    inBottomNavbar: true,
    inBottomRight: true,
    withCredentials: true,
    active: true,
    onclick: () =>
      trackEvent('Feedback', {
        description: 'click in navbar vertical'
      })
  },
  {
    name: textLang.support,
    icon: 'headset',
    to: '/support',
    type: 'Account',
    navbarVertical: true,
    navbarTop: true,
    navbarBottom: true,
    inBottomNavbar: true,
    inBottomRight: true,
    withCredentials: true,
    active: true
  },
  {
    name: textLang.TC,
    icon: 'info-circle',
    to: '/termsandconditions',
    type: 'Account',
    navbarVertical: false,
    navbarTop: true,
    navbarBottom: true,
    withCredentials: false,
    active: true,
    disableInDesktop: true
  },
  {
    name: 'Login',
    icon: '',
    to: '/login',
    type: 'Authentication',
    navbarVertical: false,
    navbarTop: true,
    withCredentials: false,
    active: true
  },
  {
    name: 'Botdetails',
    icon: 'cog',
    to: '/botdetails',
    type: 'NotInVerticalBar',
    navbarVertical: true,
    navbarTop: true,
    navbarBottom: true,
    withCredentials: true,
    active: true
  },
  {
    name: 'BnbExplanationPage',
    icon: 'cog',
    to: '/bnbdiscountpage',
    type: 'NotInVerticalBar',
    navbarVertical: true,
    navbarTop: true,
    navbarBottom: true,
    withCredentials: true,
    active: true
  },
  {
    name: 'TermsOfUse',
    icon: 'cog',
    to: '/termsofuse',
    type: 'NotInVerticalBar',
    navbarVertical: false,
    navbarTop: true,
    navbarBottom: true,
    withCredentials: false,
    active: true
  },
  {
    name: 'Pricing',
    icon: 'crown',
    to: '/pricing',
    type: 'NotInVerticalBar',
    navbarVertical: true,
    navbarTop: true,
    withCredentials: false,
    active: true,
    // isVisibleOnlyForBasic: true,
    // isVisibleOnlyForPro: false,
    // color: goldColor,
    navbarBottom: true
    // specialText: textLang.goPro,
    // onclick: () =>
    //   trackEvent('Go Pro', {
    //     type: 'Navbar Vertical'
    //   })
  },
  {
    name: 'Private',
    icon: 'spinner',
    to: '/account/trader-procedure',
    type: 'Account',
    navbarVertical: true,
    navbarTop: true,
    withCredentials: false,
    active: true,
    forWannabeTrader: true,
    color: 'var(--falcon-success)',
    navbarBottom: true,
    specialText: textLang.goTrader
  },
  {
    name: 'BinanceGuide',
    icon: '',
    to: '/connect-binance-wallet',
    type: 'NotInVerticalBar',
    navbarVertical: true,
    navbarTop: true,
    navbarBottom: true,
    withCredentials: false,
    active: true
  },
  {
    name: 'BinanceGuide',
    icon: '',
    to: '/convert-on-binance',
    type: 'NotInVerticalBar',
    navbarVertical: true,
    navbarTop: true,
    navbarBottom: true,
    withCredentials: false,
    active: true
  },
  {
    name: 'BinanceDepositGuide',
    icon: '',
    to: '/deposit-binance',
    type: 'NotInVerticalBar',
    navbarVertical: true,
    navbarTop: true,
    navbarBottom: true,
    withCredentials: false,
    active: true
  },
  {
    name: 'Signup',
    icon: '',
    to: '/signup',
    type: 'Authentication',
    navbarVertical: false,
    navbarTop: true,
    withCredentials: false,
    active: true
  },
  {
    name: 'PricingHodlieAI',
    icon: '',
    to: '/pricing-hodlie-ai',
    type: 'NotInVerticalBar',
    navbarVertical: false,
    navbarTop: true,
    navbarBottom: false,
    withCredentials: false,
    active: true
  },
  {
    name: 'FAQHodlieAI',
    icon: '',
    to: '/faq-hodlie-ai',
    type: 'NotInVerticalBar',
    navbarVertical: false,
    navbarTop: true,
    navbarBottom: false,
    withCredentials: false,
    active: true
  },
  {
    name: 'UsageGuideHodlieAI',
    icon: '',
    to: '/usage-guide-hodlie-ai',
    type: 'NotInVerticalBar',
    navbarVertical: false,
    navbarTop: true,
    navbarBottom: false,
    withCredentials: false,
    active: true
  },
  {
    name: 'virtualExplain',
    icon: '',
    to: '/virtual-explain',
    type: 'NotInVerticalBar',
    navbarVertical: true,
    navbarTop: true,
    navbarBottom: true,
    withCredentials: true,
    active: true
  },
  {
    name: 'riskExplain',
    icon: '',
    to: '/risk-explain',
    type: 'NotInVerticalBar',
    navbarVertical: true,
    navbarTop: true,
    navbarBottom: true,
    withCredentials: true,
    active: true
  },
  {
    name: 'mailUnsubscribe',
    icon: '',
    to: '/mail-unsubscribe',
    type: 'NotInVerticalBar',
    navbarVertical: false,
    navbarTop: true,
    navbarBottom: false,
    withCredentials: false,
    active: true
  }
];

export const accountRoutes = {
  label: 'Account',
  children: [
    {
      name: 'Account',
      icon: 'user-circle',
      to: '/account',
      active: true
    },
    {
      name: 'Rewards',
      icon: 'gift',
      to: '/rewards',
      active: true
    },
    {
      name: 'FAQ',
      icon: 'question-circle',
      to: '/faq',
      active: true
    },
    {
      name: 'Announcements',
      icon: 'bullhorn',
      to: '/announcements',
      active: true
    },
    {
      name: 'Support',
      icon: 'headset',
      to: '/support',
      active: true
    }
  ]
};

export const authenticationRoutes = {
  label: 'Authentication',
  children: [
    {
      name: 'Login',
      icon: 'cog',
      to: '/login',
      active: true
    }
  ]
};

export const notInVerticalBarRoutes = {
  label: 'NotInVerticalBar',
  children: [
    {
      name: 'Botdetails',
      icon: 'cog',
      to: '/botdetails',
      active: true
    }
  ]
};

export const appRoutes = {
  label: 'app',
  children: [
    {
      name: 'Dashboard',
      icon: 'chart-pie',
      to: '/',
      active: true
    },
    {
      name: 'Create Bot',
      icon: 'rocket',
      to: '/invest',
      active: true
    },
    {
      name: 'Wallet',
      icon: 'wallet',
      to: '/wallet',
      active: false
    },
    {
      name: 'Top Scorers',
      icon: 'fire',
      to: '/topscorers',
      active: false
    },
    {
      name: 'Backtester',
      icon: 'chart-line',
      to: '/backtester',
      active: false
    },
    {
      name: 'Market Data',
      icon: 'poll',
      to: '/marketdata',
      active: false
    },
    {
      name: 'Activities',
      icon: 'code-branch',
      to: '/activities',
      active: false
    }
  ]
};

let backend_url = 'https://backend.hodlie.finance';
let backend_port = '443';

// Use backend.hodlie.ai if frontend is loaded from app.hodlie.ai
if (window.location.hostname === 'app.hodlie.ai') {
  backend_url = 'https://backend.hodlie.ai';
}

if (process.env.REACT_APP_ENV === 'LOCAL') {
  backend_url = 'http://localhost';
  backend_port = '5000';
} else if (process.env.REACT_APP_ENV === 'DEV') {
  backend_url = 'https://backend-dev.hodlie.finance';
} else if (process.env.REACT_APP_ENV === 'DEV-multiAsset') {
  backend_url = 'https://backend-dev-ma.hodlie.finance';
} else if (process.env.REACT_APP_ENV === 'RESEARCH') {
  backend_url = 'https://backend-research.hodlie.finance';
}

export const backend_host = backend_url;

export const serverRoutes = {
  label: 'server',
  children: {
    backend: {
      name: 'backend',
      method: 'GET',
      link: backend_url,
      port: backend_port,
      active: true
    }
  }
};

export const internalRoutes = {
  label: 'internal',
  children: {
    getUserKyc: {
      name: 'getUserKyc',
      server: 'backend',
      method: 'GET',
      path: '/user/kyc',
      active: true
    },
    createKycSession: {
      name: 'createKycSession',
      server: 'backend',
      method: 'POST',
      path: '/user/createKycSession',
      active: true
    },
    createUserSubscription: {
      name: 'createUserSubscription',
      server: 'backend',
      method: 'GET',
      path: '/user/createSubscription',
      active: true
    },
    createTraderSubscription: {
      name: 'createTraderSubscription',
      server: 'backend',
      method: 'GET',
      path: '/user/createTraderSubscription',
      active: true
    },
    manageUserSubscription: {
      name: 'manageUserSubscription',
      server: 'backend',
      method: 'POST',
      path: '/user/manageSubscription',
      active: true
    },
    loginPage: {
      name: 'loginPage',
      server: 'backend',
      method: 'POST',
      path: '/user/login',
      active: true
    },
    googleLogin: {
      name: 'googleLogin',
      server: 'backend',
      method: 'POST',
      path: '/user/google_login',
      active: true
    },
    logoutPage: {
      name: 'logoutPage',
      server: 'backend',
      method: 'POST',
      path: '/user/logout',
      active: true
    },
    getUserInfo: {
      name: 'getUserInfo',
      server: 'backend',
      method: 'GET',
      path: '/user/getInfo',
      active: true
    },
    getVirtualWallet: {
      name: 'getVirtualWallet',
      server: 'backend',
      method: 'GET',
      path: '/user/getVirtualWallet',
      active: true
    },
    getRealWallet: {
      name: 'getRealWallet',
      server: 'backend',
      method: 'GET',
      path: '/user/getRealWallet',
      active: true
    },
    getHodWallet: {
      name: 'getHodWallet',
      server: 'backend',
      method: 'GET',
      path: '/user/getHodWallet',
      active: true
    },
    getDailyBonus: {
      name: 'getDailyBonus',
      server: 'backend',
      method: 'GET',
      path: '/user/getDailyBonus',
      active: true
    },
    getFriendsList: {
      name: 'getFriendsList',
      server: 'backend',
      method: 'GET',
      path: '/user/getFriendsList',
      active: true
    },
    getLastDailyBonus: {
      name: 'getLastDailyBonus',
      server: 'backend',
      method: 'GET',
      path: '/user/getLastDailyBonus',
      active: true
    },
    getVirtualCap: {
      name: 'getVirtualCap',
      server: 'backend',
      method: 'GET',
      path: '/user/getVirtualCap',
      active: true
    },
    getTradableQuantity: {
      name: 'getTradableQuantity',
      server: 'backend',
      method: 'GET',
      path: '/user/getTradableQuantity',
      active: true
    },
    allowedCoins: {
      name: 'allowedCoins',
      server: 'backend',
      method: 'GET',
      path: '/coin/allowedCoins',
      active: true
    },
    allowedCoinsPerBotType: {
      name: 'allowedCoinsPerBotType',
      server: 'backend',
      method: 'GET',
      path: '/botTypes/allowedCoinsPerBotType',
      active: true
    },
    getMaximumAllowedFee: {
      name: 'getMaximumAllowedFee',
      server: 'backend',
      method: 'GET',
      path: '/botTypes/getMaximumAllowedFeePerBotType',
      active: true
    },
    allowedExchanges: {
      name: 'allowedExchanges',
      server: 'backend',
      method: 'GET',
      path: '/coin/allowedExchanges',
      active: true
    },
    getBotTypes: {
      name: 'getBotTypes',
      server: 'backend',
      method: 'GET',
      path: '/botTypes/gets',
      active: true
    },
    backtest: {
      name: 'backtest',
      server: 'backend',
      method: 'POST',
      path: '/backtest/backtest',
      active: true
    },
    createBot: {
      name: 'createBot',
      server: 'backend',
      method: 'POST',
      path: '/bots/create',
      active: true
    },
    botsDetails: {
      name: 'botsDetails',
      server: 'backend',
      method: 'POST',
      path: '/bots/getsVirtual',
      active: true
    },
    realBotsDetails: {
      name: 'realBotsDetails',
      server: 'backend',
      method: 'POST',
      path: '/bots/getsReal',
      active: true
    },
    getBotInfo: {
      name: 'getBotInfo',
      server: 'backend',
      method: 'GET',
      path: '/bots/get',
      active: true
    },
    setStatus: {
      name: 'setStatus',
      server: 'backend',
      method: 'POST',
      path: '/bots/setStatus',
      active: true
    },
    setBotNotifications: {
      name: 'setBotNotifications',
      server: 'backend',
      method: 'POST',
      path: '/notifications/setBotNotifications',
      active: true
    },
    setProNotifications: {
      name: 'setProNotifications',
      server: 'backend',
      method: 'POST',
      path: '/notifications/setProNotifications',
      active: true
    },
    updateBotParameter: {
      name: 'updateBotParameter',
      server: 'backend',
      method: 'POST',
      path: '/bots/updateBotParameter',
      active: true
    },
    editBnbBurnStatus: {
      name: 'editBnbBurnStatus',
      server: 'backend',
      method: 'POST',
      path: '/user/editBnbBurnStatus',
      active: true
    },
    getBnbBurnStatus: {
      name: 'getBnbBurnStatus',
      server: 'backend',
      method: 'GET',
      path: '/user/getBnbBurnStatus',
      active: true
    },
    registerUserNotifications: {
      name: 'registerUserNotifications',
      server: 'backend',
      method: 'POST',
      path: '/notifications/registerUser',
      active: true
    },
    getBinanceLiveCoins: {
      name: 'getBinanceLiveCoins',
      server: 'backend',
      method: 'GET',
      path: '/coin/getBinanceLiveCoins',
      active: true
    },
    getAllUserTransaction: {
      name: 'getAllUserTransaction',
      server: 'backend',
      method: 'GET',
      path: '/logs/getAllUserTransaction',
      active: true
    },
    getUserTransactions: {
      name: 'getUserTransactions',
      server: 'backend',
      method: 'GET',
      path: '/logs/getUserTransactions',
      active: true
    },
    getUserRealTransactions: {
      name: 'getUserRealTransactions',
      server: 'backend',
      method: 'GET',
      path: '/logs/getUserRealTransactions',
      active: true
    },
    addBotTransaction: {
      name: 'addBotTransaction',
      server: 'backend',
      method: 'POST',
      path: '/logs/addBotTransaction',
      active: true
    },
    getAllUserBotStatsSmartWallet: {
      name: 'getAllUserBotStatsSmartWallet',
      server: 'backend',
      method: 'GET',
      path: '/logs/getAllUserBotStatsSmartWallet',
      active: true
    },
    getAllUserRealBotStatsSmartWallet: {
      name: 'getAllUserRealBotStatsSmartWallet',
      server: 'backend',
      method: 'GET',
      path: '/logs/getAllUserRealBotStatsSmartWallet',
      active: true
    },
    getBotStatsSmartWallet: {
      name: 'getBotStatsSmartWallet',
      server: 'backend',
      method: 'GET',
      path: '/logs/getBotStatsSmartWallet',
      active: true
    },
    getGoodMorningNotifications: {
      name: 'getGoodMorningNotifications',
      server: 'backend',
      method: 'GET',
      path: '/logs/getGoodMorningNotifications',
      active: true
    },
    getSimplifiedGoodMorningNotifications: {
      name: 'getSimplifiedGoodMorningNotifications',
      server: 'backend',
      method: 'GET',
      path: '/logs/getSimplifiedGoodMorningNotifications',
      active: true
    },
    getRealSimplifiedGoodMorningNotifications: {
      name: 'getRealSimplifiedGoodMorningNotifications',
      server: 'backend',
      method: 'GET',
      path: '/logs/getRealSimplifiedGoodMorningNotifications',
      active: true
    },
    getUserInvestments: {
      name: 'getUserInvestments',
      server: 'backend',
      method: 'GET',
      path: '/logs/getUserInvestments',
      active: true
    },
    getUserRealInvestments: {
      name: 'getUserRealInvestments',
      server: 'backend',
      method: 'GET',
      path: '/logs/getUserRealInvestments',
      active: true
    },
    getAllUserBotStats: {
      name: 'getAllUserBotStats',
      server: 'backend',
      method: 'GET',
      path: '/logs/getAllUserBotStats',
      active: true
    },
    getAllBotStats: {
      name: 'getAllBotStats',
      server: 'backend',
      method: 'GET',
      path: '/logs/getAllBotStats',
      active: true
    },
    getAllBotTransactions: {
      name: 'getAllBotTransactions',
      server: 'backend',
      method: 'GET',
      path: '/logs/getAllBotTransactions',
      active: true
    },
    getPredictionDirection: {
      name: 'getPredictionDirection',
      server: 'backend',
      method: 'GET',
      path: '/bots/getPredictionDirection',
      active: true
    },
    loggedChangePassword: {
      name: 'loggedChangePassword',
      server: 'backend',
      method: 'POST',
      path: '/user/loggedChangePassword',
      active: true
    },
    forgotPassword: {
      name: 'forgotPassword',
      server: 'backend',
      method: 'POST',
      path: '/user/forgotPassword',
      active: true
    },
    notLoggedInChangePassword: {
      name: 'notLoggedInChangePassword',
      server: 'backend',
      method: 'POST',
      path: '/user/notLoggedInChangePassword',
      active: true
    },
    changeUsername: {
      name: 'changeUsername',
      server: 'backend',
      method: 'POST',
      path: '/user/changeUsername',
      active: true
    },
    setExchangeCredentials: {
      name: 'setExchangeCredentials',
      server: 'backend',
      method: 'POST',
      path: '/user/setExchangeCredentials',
      active: true
    },
    removeExchangeCredentials: {
      name: 'removeExchangeCredentials',
      server: 'backend',
      method: 'POST',
      path: '/user/removeExchangeCredentials',
      active: true
    },
    getUserExchanges: {
      name: 'getUserExchanges',
      server: 'backend',
      method: 'POST',
      path: '/user/getUserExchanges',
      active: true
    },
    addToWaitingRoom: {
      name: 'addToWaitingRoom',
      server: 'backend',
      method: 'POST',
      path: '/user/addToWaitingRoom',
      active: true
    },
    getUniqueCodeInfo: {
      name: 'getUniqueCodeInfo',
      server: 'backend',
      method: 'POST',
      path: '/user/getUniqueCodeInfo',
      active: true
    },
    fromWaitingToUsers: {
      name: 'fromWaitingToUsers',
      server: 'backend',
      method: 'POST',
      path: '/user/fromWaitingToUsers',
      active: true
    },
    getBotLastStat: {
      name: 'getBotLastStat',
      server: 'backend',
      method: 'GET',
      path: '/logs/getBotLastStat',
      active: true
    },
    insertSignupForm: {
      name: 'insertSignupForm',
      server: 'backend',
      method: 'POST',
      path: '/user/insertSignupForm',
      active: true
    },
    getForm: {
      name: 'getForm',
      server: 'backend',
      method: 'GET',
      path: '/user/getForm',
      active: true
    },
    changeFrontedVersion: {
      name: 'changeFrontedVersion',
      server: 'backend',
      method: 'POST',
      path: '/user/changeFrontedVersion',
      active: true
    },
    getUserExchangesOverview: {
      name: 'getUserExchangesOverview',
      server: 'backend',
      method: 'POST',
      path: '/user/getUserExchangesOverview',
      active: true
    },
    backendVer: {
      name: 'backendVer',
      server: 'backend',
      method: 'GET',
      path: '/ver',
      active: true
    },
    getCoinDataWithGranularity: {
      name: 'getCoinDataWithGranularity',
      server: 'backend',
      method: 'GET',
      path: '/coin/getCoinDataWithGranularity',
      active: true
    },
    getHomeGoodMorning: {
      name: 'getHomeGoodMorning',
      server: 'backend',
      method: 'GET',
      path: '/user/getHomeGoodMorning',
      active: true
    },
    getHomeWalletTrend: {
      name: 'getHomeWalletTrend',
      server: 'backend',
      method: 'GET',
      path: '/user/getHomeWalletTrend',
      active: true
    },
    getUserAdvisedBot: {
      name: 'getUserAdvisedBot',
      server: 'backend',
      method: 'GET',
      path: '/user/getUserAdvisedBot',
      active: true
    },
    getKYC: {
      name: 'getKYC',
      server: 'backend',
      method: 'GET',
      path: '/user/kyc',
      active: true
    },
    registration: {
      name: 'registration',
      server: 'backend',
      method: 'POST',
      path: '/user/registration',
      active: true
    },
    confirmMail: {
      name: 'confirmMail',
      server: 'backend',
      method: 'POST',
      path: '/user/confirm_email',
      active: true
    },
    resendMail: {
      name: 'resendMail',
      server: 'backend',
      method: 'POST',
      path: '/user/resend_confirm',
      active: true
    },
    getWallet: {
      name: 'getWallet',
      server: 'backend',
      method: 'GET',
      path: '/wallet/stats',
      active: true
    },
    getBotWallet: {
      name: 'getBotWallet',
      server: 'backend',
      method: 'GET',
      path: '/wallet/bot_stats',
      active: true
    },
    getPopUps: {
      name: 'getPopUps',
      server: 'backend',
      method: 'GET',
      path: '/popups',
      active: true
    },
    popupFeedback: {
      name: 'popupFeedback',
      server: 'backend',
      method: 'POST',
      path: '/popups/feedback',
      active: true
    },
    changeName: {
      name: 'changeName',
      server: 'backend',
      method: 'POST',
      path: '/bots/change_name',
      active: true
    },
    requestTraderInfo: {
      name: 'requestTraderInfo',
      server: 'backend',
      method: 'POST',
      path: '/user/requestTraderInfo',
      active: true
    },
    requestTraderContract: {
      name: 'requestTraderContract',
      server: 'backend',
      method: 'POST',
      path: '/user/requestTraderContract',
      active: true
    },
    getSentiment: {
      name: 'getSentiment',
      server: 'backend',
      method: 'GET',
      path: '/insights/sentiment',
      active: true
    },
    getOrderTable: {
      name: 'getOrderTable',
      server: 'backend',
      method: 'GET',
      path: '/insights/order_table',
      active: true
    },
    getUnsubscribe: {
      name: 'getUnsubscribe',
      server: 'backend',
      method: 'GET',
      path: '/user/mail-unsubscribe',
      active: true
    },
    getMarkets: {
      name: 'getMarkets',
      server: 'backend',
      method: 'GET',
      path: '/insights/markets',
      active: true
    },
    getTA: {
      name: 'getTA',
      server: 'backend',
      method: 'GET',
      path: '/insights/ta',
      active: true
    },
    benchmarkCoins: {
      name: 'benchmarkCoins',
      server: 'backend',
      method: 'GET',
      path: '/wallet/benchmark/coins',
      active: true
    },
    walletBenchmark: {
      name: 'walletBenchmark',
      server: 'backend',
      method: 'GET',
      path: '/wallet/benchmark',
      active: true
    },
    walletAvailability: {
      name: 'walletAvailability',
      server: 'backend',
      method: 'GET',
      path: '/wallet/coins',
      active: true
    },
    walletAvailability_binance: {
      name: 'walletAvailability_binance',
      server: 'backend',
      method: 'GET',
      path: '/wallet/coins/binance',
      active: true
    },
    deleteAccountData: {
      name: 'deleteAccountData',
      server: 'backend',
      method: 'POST',
      path: '/user/deleteAccountData',
      active: true
    },
    botSearch: {
      name: 'botSearch',
      server: 'backend',
      method: 'GET',
      path: '/bots/search',
      active: true
    },
    getBots: {
      name: 'getBots',
      server: 'backend',
      method: 'GET',
      path: '/bots',
      active: true
    },
    getWalletBot: {
      name: 'getWalletBot',
      server: 'backend',
      method: 'GET',
      path: '/wallet/bot_stats',
      active: true
    },
    docusignSignature: {
      name: 'docusignSignature',
      server: 'backend',
      method: 'POST',
      path: '/docusign/sign_private',
      active: true
    },
    docusignCompleted: {
      name: 'docusignCompleted',
      server: 'backend',
      method: 'POST',
      path: '/docusign/sign_completed',
      active: true
    }
  }
};

export const internalRoutesV2 = {
  changeAllocation: {
    path: '/bot/<bot_id>/change_allocation',
    active: true
  },
  botPac: {
    path: '/bots/<bot_id>/pac',
    active: true
  },
  walletSnapshot: {
    path: '/wallet/snapshot/<snapshot_id>',
    active: true
  },
  upgradeSubscription: {
    path: '/user/subscription/upgrade',
    active: true
  },
  referralStatus: {
    path: '/referral/status',
    active: true
  },
  redeemDiscount: {
    path: '/referral/redeem/discount',
    active: true
  },
  terminateAndSell: {
    path: '/bots/<bot_id>/ds_terminate_and_sell',
    active: true
  },
  coinPrices: {
    path: '/coin/prices/<exchange>',
    active: true
  },
  setBotStatus: {
    path: '/bots/<bot_id>/set_status',
    active: true
  },
  createBotLimits: {
    path: '/bots/create/limits',
    active: true
  },
  createBot: {
    path: '/bots/create',
    active: true
  },
  botVolume: {
    path: '/bots/<bot_id>/volumes',
    active: true
  },
  setRiskForm: {
    path: '/user/save_portfolios_risk_form',
    active: true
  },
  upgradeTrial: {
    path: '/user/subscription/change-trial',
    active: true
  },
  botOrders: {
    path: '/bots/<bot_id>/orders',
    active: true
  },
  upgradePreview: {
    path: '/user/subscription/upgrade/preview',
    active: true
  },
  virtual_trial: {
    path: '/user/virtual_trial',
    active: true
  }
};

export const usefulRoutes = {
  label: 'useful',
  children: {
    mailInfo: {
      name: 'mailInfo',
      link: 'mailto:info@hodlie.net',
      active: true
    },
    mailHello: {
      name: 'mailHello',
      link: 'mailto:hello@hodlie.net',
      active: true
    }
  }
};

export default [appRoutes, accountRoutes];
